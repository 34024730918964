import { Component, effect, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserNotificationSettings } from 'src/models/user.model';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { UserService } from '@root/services/user-service/user.service';
import { tapResponse } from '@ngrx/operators';
import { NotificationService } from '@root/services/notification.service';
import { GeneralStore } from '@root/store/general.store';
import { DialogHeaderComponent } from '@shared/components/dialog-header/dialog-header.component';
import { TooltipComponent } from '../../components/tooltip/tooltip.component';
import { NewActionButtonComponent } from '../../components/new-action-button/new-action-button.component';

@Component({
  selector: 'app-notification-setting',
  templateUrl: './notification-setting.dialog.html',
  styleUrls: ['./notification-setting.dialog.scss'],
  standalone: true,
  host: { class: 'app-dialog-content' },
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatSlideToggleModule,
    FormsModule,
    MatTooltipModule,
    NgIf,
    DialogHeaderComponent,
    TooltipComponent,
    NewActionButtonComponent
  ],
})
export class NotificationSettingDialog {
  userNotificationSettings!: UserNotificationSettings;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public masterEmailToggle: any;
  public userName!: string;
  readonly generalStore = inject(GeneralStore);

  tooltipPosition: TooltipPosition = 'above';

  constructor(
    public dialogRef: MatDialogRef<NotificationSettingDialog>,
    private userService: UserService,
    private notificationService: NotificationService,
  ) {
    /* eslint-disable @typescript-eslint/typedef */
    effect(() => {
      const tempUserInfo = this.generalStore.userInfo();
      if (tempUserInfo) {
        this.userName = tempUserInfo.userFullName || '';

        this.userNotificationSettings = {
          notifyEmailAssignmentUser: tempUserInfo.notifyEmailAssignmentUser === 1,
          notifyEmailAssignmentGroup: tempUserInfo.notifyEmailAssignmentGroup === 1,
          notifyEmailReminder: tempUserInfo.notifyEmailReminder === 1,
          notifyEmailOther: tempUserInfo.notifyEmailOther === 1,
        };
        this.setDefaultValueSelectAll(this.userNotificationSettings);
        this.masterEmailToggle = !Object.values(this.userNotificationSettings).includes(false);
      }
    });
    effect(() => {});
  }

  setDefaultValueSelectAll(userNotificationSettings: UserNotificationSettings) {
    this.masterEmailToggle = !Object.values(userNotificationSettings).includes(false);
  }

  changeSelectAll() {
    if (this.masterEmailToggle === undefined || this.userNotificationSettings === undefined) {
      return;
    }
    let setting: keyof UserNotificationSettings;
    for (setting in this.userNotificationSettings) {
      //Convert each value to boolean
      this.userNotificationSettings[setting] = this.masterEmailToggle;
    }
    this.commitChange();
  }

  public readonly commitChange = (): void => {
    this.setDefaultValueSelectAll(this.userNotificationSettings);
    this.userService
      .updateNotificationSettings(this.userNotificationSettings)
      .pipe(
        tapResponse({
          next: () => {
            this.notificationService.openSuccessSnackBar(
              'User notification settings have been updated.',
            );
          },
          error: () => {
            this.notificationService.openErrorSnackBar(
              'Failed to update user notification settings.',
            );
          },
        }),
      )
      .subscribe();
  };
}
